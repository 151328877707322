<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar color="#083272" dark>
        <v-toolbar-title>
          <h3 class="font-weight-bold suptitle">제품 버전 관리</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" light @click="mdl_newVersion">
          <v-icon>mdi-plus</v-icon>
          New Version
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-container class="py-6">
        <v-row class="pb-2">
          <v-col cols="12"><h2>Product Info</h2></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pt-2">
          <v-col cols="12">
            <v-text-field
              outlined
              light
              dense
              hide-details
              label="제품명"
              class="px-1 pt-0"
              readonly
              v-model="product.product_name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><h2>등록된 버전 목록</h2></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="allocatedHeader"
              :items="versions"
              :search="SearchKwd"
              class="elevation-0"
              dense
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="deleteVersion(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn color="#FF4D00" dark block @click="mdl_close">
              CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="mdl_newVersion_show" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New Version</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="제품버전"
                  class="px-1 pt-0"
                  v-model="add_version_frm.product_version"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="btnAddProductVersion">
                  ADD
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getProductVersion,
  newProductVersion,
  delProductVersion,
} from "@/api/Admin/Product";
export default {
  props: {
    product: {
      type: Object,
      require: true,
    },
    closeBtn: {
      type: Function,
      require: false,
    },
  },
  mounted() {
    this.getProductVersion();
  },
  methods: {
    async getProductVersion() {
      const versions = await getProductVersion(this.product.product_uuid);
      if (versions.success) this.versions = versions.data;
    },
    mdl_newVersion() {
      this.mdl_newVersion_show = true;
    },
    mdl_close() {
      if (this.closeBtn != null) this.closeBtn();
    },
    async deleteVersion(item) {
      const data = await delProductVersion(item);
      if (data.success) {
        await this.getProductVersion();
        this.mdl_newVersion_show = false;
      }
    },
    async btnAddProductVersion() {
      if (
        this.add_version_frm.product_version == "" ||
        this.add_version_frm.product_version == null
      )
        return alert("값이 공백입니다!!");
      const data = await newProductVersion({
        product_uuid: this.product.product_uuid,
        product_version: this.add_version_frm.product_version,
      });
      if (data.success) {
        await this.getProductVersion();
        this.mdl_newVersion_show = false;
      }
    },
  },
  data() {
    return {
      mdl_newVersion_show: false,
      allocatedHeader: [
        {
          text: "버전",
          align: "start",
          sortable: true,
          value: "product_version",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      versions: [],
      SearchKwd: "",
      add_version_frm: {
        product_version: null,
      },
    };
  },
};
</script>
