<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 제품 등록 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Product List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newProductBtn">
              <v-icon>mdi-plus</v-icon>
              New Product
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="productSearch"
                    append-icon="mdi-magnify"
                    label="제품 검색"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="accountHeader"
                    :items="accountList"
                    class="elevation-0"
                    :items-per-page="15"
                    height="500px"
                    :search="productSearch"
                    dense
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        @click="versionManageView(item)"
                      >
                        mdi-diversify
                      </v-icon>
                      <v-icon small class="mr-2" @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <h5>삭제시 복구가 불가능 합니다.<br />정말 삭제 하시겠습니까?</h5>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">
            삭제
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewProduct" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New Product</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Product Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="제품명"
                  class="px-1 pt-0"
                  v-model="product_frm.product_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="제품 설명"
                  class="px-1 pt-0"
                  v-model="product_frm.product_description"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="product_frm.product_type_cloud"
                  :label="`Cloud Product: ${
                    product_frm.product_type_cloud ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="product_frm.product_type_etc"
                  :label="`ETC Product: ${
                    product_frm.product_type_etc ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="newProductSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditProduct" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Edit Product</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Product Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="제품명"
                  class="px-1 pt-0"
                  v-model="edit_product_frm.product_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="제품 설명"
                  class="px-1 pt-0"
                  v-model="edit_product_frm.product_description"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="edit_product_frm.product_type_cloud"
                  :label="`Cloud Product: ${
                    edit_product_frm.product_type_cloud ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="edit_product_frm.product_type_etc"
                  :label="`ETC Product: ${
                    edit_product_frm.product_type_etc ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="editProductSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVersionConf" max-width="800px">
      <ManageVersion
        v-if="dialogVersionConf"
        :closeBtn="versionManageClose"
        :product="productForVersion"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getProduct,
  delProduct,
  newProduct,
  putProduct,
} from "@/api/Admin/Product";
import randomstring from "randomstring";
import ManageVersion from "@/components/Views/Admin/Products/modal/ManageVersion";
export default {
  components: {
    ManageVersion,
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      const data = await getProduct();
      this.accountList = [];
      if (data.success) {
        this.accountList = data.data;
      }
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    async deleteItemConfirm() {
      await delProduct(this.selectedItem.product_uuid);
      this.dialogDelete = false;
      this.selectedItem = null;
      this.getProduct();
    },
    newProductBtn() {
      this.product_frm = {
        product_name: null,
        product_description: null,
        product_type_cloud: false,
        product_type_etc: false,
      };
      this.dialogNewProduct = true;
    },
    async newProductSaveBtn() {
      await newProduct(this.product_frm);
      this.dialogNewProduct = false;
      await this.getProduct();
    },
    passAutoFill() {
      const str = randomstring.generate(7);
      this.product_frm.product_pw = str;
    },
    editItem(item) {
      this.edit_product_frm = item;
      this.dialogEditProduct = true;
    },
    versionManageView(item) {
      this.productForVersion = item;
      this.dialogVersionConf = true;
    },
    versionManageClose() {
      this.dialogVersionConf = false;
    },
    async editProductSaveBtn() {
      await putProduct(
        this.edit_product_frm.product_uuid,
        this.edit_product_frm
      );
      this.dialogEditProduct = false;
      await this.getProduct();
    },
  },
  data() {
    return {
      accountList: [],
      accountHeader: [
        {
          text: "제품명",
          align: "start",
          sortable: true,
          value: "product_name",
        },
        {
          text: "고유코드",
          align: "start",
          value: "product_uuid",
        },
        {
          text: "설명",
          align: "start",
          sortable: false,
          value: "product_description",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: null,

      productSearch: null,

      dialogDelete: false,
      dialogNewProduct: false,
      dialogEditProduct: false,
      dialogVersionConf: false,

      productForVersion: {
        product_name: null,
        product_addr: null,
        product_type_cloud: false,
        product_type_etc: false,
      },

      product_frm: {
        product_name: null,
        product_addr: null,
        product_type_cloud: false,
        product_type_etc: false,
      },
      edit_product_frm: {
        product_name: null,
        product_addr: null,
        product_type_cloud: false,
        product_type_etc: false,
      },
    };
  },
};
</script>
